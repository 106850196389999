import ApiService from '@/shared/services/api.service';
const APP_ATLAS_SYSTEM_LAYERS_KEY = 'st_atlas_system_layers';
const APP_ATLAS_PARCEL_KEY = 'st_atlas_parcel';
const APP_ATLAS_TEMP_VISIBLE_LAYERS = 'st_atlas_temp_visible_layers';

export class AtlasService {
    static getLayers(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/layers/me', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static getPublicLayers(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/public-layers/', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
   static getPersistedLayers() {
        const layersString = window.localStorage.getItem(APP_ATLAS_SYSTEM_LAYERS_KEY);
        return layersString ? JSON.parse(layersString) : {};
    }

    static persistLayers(layers) {
        const layersString = JSON.stringify(layers);
        window.localStorage.setItem(APP_ATLAS_SYSTEM_LAYERS_KEY, layersString);
    }

    static persistParcel(parcel) {
        const parcelString = JSON.stringify(parcel);
        window.localStorage.setItem(APP_ATLAS_PARCEL_KEY, parcelString);
    }

    static persistVisiblePrintWidgetLayers(layerIds) {
        const layerIdsString = JSON.stringify(layerIds);
        window.localStorage.setItem(APP_ATLAS_TEMP_VISIBLE_LAYERS, layerIdsString);
    }

    static getVisiblePrintWidgetLayers() {
        const layerIdsString = window.localStorage.getItem(APP_ATLAS_TEMP_VISIBLE_LAYERS);
        return layerIdsString ? JSON.parse(layerIdsString) : [];
    }
}
