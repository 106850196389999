import ApiService from '@/shared/services/api.service';

export class PaymentService {
    static getUploadToken(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/payments/token?product_identifier=${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    }
    static savePaymentOrder(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/payments/op', { ...data } )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }

    static cancelPaymentOrder(data) {
        return new Promise((resolve, reject) => {
            ApiService.put( '/payments/cancel', { ...data } )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    }
}