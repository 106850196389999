import Errors from '@/shared/error/errors';
import { ApplicationsService } from '../applications-service';

// action types
export const DO_FETCH = 'doFetch';

// mutation types
export const FETCH_SUCCESS = 'fetchSuccess';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';
export const SET_SORT = 'setSort';

const state = {
    rows: [],
    total: 0,
    storeFilters: {},
    currentPage: 1,
    storeSort: {sortBy: '', sortDesc: false},
};

const getters = {
    rows: (state) => state.rows || [],
    total: (state) => state.total,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
    sorting: (state) => state.storeSort,
    complainPermissions : (state, getters, rootState, rootGetters) => {
        const currentUser = rootGetters['auth/currentUser'];
        return {
            hasPermissionToStaffComplains: new ComplainPermissions(currentUser).readStaff,
        }
    }
};

const actions = {
    [DO_FETCH](context, { id, params }) {
        context.commit('shared/activateLoading', 'paymentsList/doFetch', { root: true });
        return new Promise((resolve, reject) => {
            const queryParams = {
                ...params,
                query: `product_identifier_type eq 'application' and product_identifier eq '${id}'`,
                sort: '-created_date'
            };
            ApplicationsService.paymentsList({ params: queryParams })
            .then((data) => {
                context.commit(FETCH_SUCCESS, {
                    rows: data.data,
                    total: data?.pagination?.total,
                });
                resolve();
            })
            .catch(({ error }) => {
                Errors.handle(error);
                reject(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'paymentsList/doFetch', { root: true });
            })
        });
    }
};

const mutations = {
    [FETCH_SUCCESS](state, payload) {
        state.rows = payload.rows;
        state.total = payload.total;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
    [SET_SORT](state, value) {
        state.storeSort = value;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
