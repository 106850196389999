import Errors from '@/shared/error/errors';
import { ApplicationsService } from '../applications-service';

// action types
export const CREATE_PAYMENT_LINK = 'createPaymentLink';

const state = {
};

const getters = {
};

const actions = {
    [CREATE_PAYMENT_LINK](context, { id, amount, email }) {
        context.commit('shared/activateLoading', 'applications/createPaymentLink', { root: true });
        return new Promise((resolve, reject) => {
            ApplicationsService.createPaymentLink(id, amount, email)
                .then((data) => resolve(data))
                .catch((error) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'applications/createPaymentLink', { root: true });
                });
        })
    },
};

const mutations = {
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};