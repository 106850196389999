
import { AtlasService } from '@/modules/applications/atlas-service';
import Errors from '@/shared/error/errors';
import { SYSTEM_LAYERS_ORDER } from "@/modules/atlas/utils/constants";
import { storeAsync } from '@/app-module';

// action types
export const DO_INIT_STATE = 'doInit';
export const GET_LAYERS = 'getLayers';
export const SET_VISIBILE_SYSTEM_LAYERS = 'setVisibileSystemLayers';
export const SET_LAYER_VISIBILITY = 'setLayerVisibility';
export const SET_SELECTED_PARCEL = 'setSelectedParcel';
export const GET_VISIBLE_PRINTWIDGET_LAYERS = 'getVisiblePrintWidgetLayers';
export const SET_VISIBLE_PRINTWIDGET_LAYERS = 'setVisiblePrintWidgetLayers';
export const DO_SET_MEASUREMENT = 'doSetMeasurement';

// mutation types
export const INIT_STATE = 'init';
export const SET_LAYERS = 'setLayers';
export const SET_SIMPLE_LAYERS = 'setSimpleLayers';
export const SET_SYSTEM_LAYERS = 'setSystemLayers';
export const SET_VISIBLE_LAYER = 'setVisibleLayer';
export const SET_MEASUREMENT = 'setMeasurement';

const getDefaultState = (state) => {
    return {
        selected: state?.selected || {},
        layers: [],
    	simpleLayers: [],
    	systemLayers: [],
        visiblePrintWidgetLayers: [],
        measurement: null,
    };
};

const state = getDefaultState();

const getters = {
	layers: (state) => state.layers,
    simpleLayers: (state) => state.simpleLayers,
    systemLayers: (state) => state.systemLayers,
    selectedLayers: (state) => state.selected,
    visiblePrintWidgetLayers: (state) => state.visiblePrintWidgetLayers,
    measurement: (state) => state.measurement,
};

const mutations = {
	[INIT_STATE](state, payload) {
        Object.assign(state, getDefaultState(payload));
    },
	[SET_LAYERS](state, layers) {
        state.layers = layers ?? [];
    },
    async [SET_SIMPLE_LAYERS](state, values) {
        const {data, realEstates} = values;
        const countyUatId = await storeAsync().getters['shared/defaultCountyUatId'];

        let components = [];
        (data ?? []).forEach(layer => {
            components = [...components, ...layer.components];
        });

        const countyEstates = realEstates.map((el)=> el.territorial_administrative_unit_id);
        countyEstates.push(countyUatId);
        state.simpleLayers = components.filter((item) => countyEstates.includes(item.uat_id));
    },
    [SET_SYSTEM_LAYERS](state, layers) {
        const systemLayers = layers.find((layer) => layer.layer_type === 'container' && layer.is_system_layer)?.components;
        const mappedSystemLayers = systemLayers.map((layer) => {
            let has_index = false;
            let index_order = null;
            SYSTEM_LAYERS_ORDER.forEach( (item) => {
                if (item.feature_service_url.search(layer.feature_service_url) >= 0) {
                    index_order = item.index
                    has_index = true;
                }
            });
            return {
                ...layer,
                has_index,
                index_order,
            }
        });
        state.systemLayers = mappedSystemLayers;
    },
    [SET_VISIBLE_LAYER](state, layer) {
        const layerId = layer.id;
        if (state.selected[layerId]) {
        	state.selected[layerId].visible = layer.visible;
        } else {
        	state.selected[layerId] = { visible: true };
        }
    },
    [SET_MEASUREMENT](state, measurement) {
        state.measurement = measurement;
    }
}

const actions = {
	async [DO_INIT_STATE](context) {
        const persistedLayers = await AtlasService.getPersistedLayers();
        context.commit(INIT_STATE, { selected: persistedLayers });
    },
    async [SET_LAYER_VISIBILITY](context, layer) {
        context.commit(SET_VISIBLE_LAYER, layer);
        const selectedLayers = await context.getters.selectedLayers;
        AtlasService.persistLayers(selectedLayers);
    },
	[GET_LAYERS](context, {isStaff, realEstates}) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'atlas-layer-manager/getLayers', { root: true });
            const params =  {
                limit: 100,
                sort: 'created_date',
                preview_visibility: isStaff ? 'can_access_staff' : 'can_access_citizen',
            };
            const serviceMethod = isStaff ? 'getLayers' : 'getPublicLayers';
            AtlasService[serviceMethod]({params})
                .then(async (data) => {
                    context.commit(SET_LAYERS, data);
                    context.commit(SET_SYSTEM_LAYERS, data);
                    context.commit(SET_SIMPLE_LAYERS, {data, realEstates});
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'atlas-layer-manager/getLayers', { root: true });
                });
        });
    },
    [SET_SELECTED_PARCEL](context, parcel) {
        AtlasService.persistParcel(parcel);
    },
    [GET_VISIBLE_PRINTWIDGET_LAYERS](context) {
        return AtlasService.getVisiblePrintWidgetLayers();
    },
    [SET_VISIBLE_PRINTWIDGET_LAYERS](context, layerIds) {
        AtlasService.persistVisiblePrintWidgetLayers(layerIds);
    },
    [DO_SET_MEASUREMENT](context, data) {
        context.commit(SET_MEASUREMENT, data);
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
