import Errors from '@/shared/error/errors';
import { PaymentService } from '../payment-service';

// action types
export const GET_UPLOAD_TOKEN = 'getUploadToken';
export const SAVE_PAYMENT_ORDER = 'savePaymentOrder';
export const CANCEL_PAYMENT_ORDER = 'cancelPaymentOrder';

// mutation types
export const SET_UPLOAD_TOKEN = 'setUploadToken';

const getDefaultState = () => {
    return {
        uploadToken: null,
    }
};
const state = getDefaultState();

const getters = {
    uploadAccessToken: (state) => state.uploadToken,
};

const actions = {
    [GET_UPLOAD_TOKEN](context, params) {
        return new Promise((resolve, reject) => {
            PaymentService.getUploadToken(params.id)
                .then((response) => {
                    if (response.headers['x-file-upload-access-token']) {
                        context.commit(SET_UPLOAD_TOKEN, response.headers['x-file-upload-access-token']);
                    }
                    resolve();
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject(error);
                })
        });
    },
    [SAVE_PAYMENT_ORDER](context, params) {
        context.commit('shared/activateLoading', 'payments/savePaymentOrder', { root: true });
        return new Promise((resolve, reject) => {
            PaymentService.savePaymentOrder(params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'payments/savePaymentOrder', { root: true });
                })
        });
    },
    [CANCEL_PAYMENT_ORDER](context, params) {
        context.commit('shared/activateLoading', 'payments/cancelPaymentOrder', { root: true });
        return new Promise((resolve, reject) => {
            PaymentService.cancelPaymentOrder(params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'payments/cancelPaymentOrder', { root: true });
                })
        });
    }
};

const mutations = {
    [SET_UPLOAD_TOKEN](state, payload) {
        state.uploadToken = payload;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
