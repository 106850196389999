import applicationsFormStore from '@/modules/applications/store/applications-form-store';
import applicationsListStore from '@/modules/applications/store/applications-list-store';
import applicationsActivitiesStore from '@/modules/applications/store/applications-activities-store';
import applicationsPaymentStore from '@/modules/applications/store/applications-payment-store';
import applicationsPaymentsListStore from '@/modules/applications/store/applications-payments-list-store';
import applicationsPaymentsFormStore from '@/modules/applications/store/applications-payments-form-store';
import urbanismRegulationZonesFormStore from '@/modules/administrator/store/urbanism-regulation-zones-form-store';
import urbanismRegulationZonesListStore from '@/modules/administrator/store/urbanism-regulation-zones-list-store';
import atlasStore from '@/modules/applications/store/atlas-store';

export default {
    namespaced: true,

    modules: {
        form: applicationsFormStore,
        list: applicationsListStore,
        activity: applicationsActivitiesStore,
        payment: applicationsPaymentStore,
        paymentsList: applicationsPaymentsListStore,
        paymentsForm: applicationsPaymentsFormStore,
        urbanismRegulationZonesForm: urbanismRegulationZonesFormStore,
        urbanismRegulationZonesList: urbanismRegulationZonesListStore,
        atlas: atlasStore,
    },
};
